import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from 'reactstrap/lib/Button';
import Collapse, { CollapseProps } from 'reactstrap/lib/Collapse';

import { useFormContext } from 'react-hook-form';

const invalidFieldsSelector =
  'input[autofocus].is-invalid, input.is-invalid, .is-invalid input, select[autofocus].is-invalid, select.is-invalid, .is-invalid select, textarea[autofocus].is-invalid, textarea.is-invalid, .is-invalid textarea';

interface OwnProps {
  btnColor?: string;
  formElement?: React.MutableRefObject<HTMLElement>;
  externalAlertMessage?: string;
}

type Props = Readonly<OwnProps & Omit<CollapseProps, 'isOpen'>>;

const ValidationAlert: React.FC<Props> = ({ btnColor = 'link', formElement, externalAlertMessage, ...props }) => {
  const { t } = useTranslation();
  const { formState, trigger } = useFormContext();
  const { isValid } = formState;

  const handleClick = React.useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      const form = formElement?.current || e.currentTarget.closest('form');
      await trigger();

      setTimeout(() => {
        const firstField = form.querySelector<HTMLInputElement>(invalidFieldsSelector);
        if (firstField) firstField.focus();
      }, 0);
    },
    [formElement, trigger]
  );

  return (
    // TODO: isValid doesn't update when form is filled via API/User login
    // https://github.com/react-hook-form/react-hook-form/issues/2755
    <Collapse {...props} isOpen={!isValid || !!externalAlertMessage}>
      <div className="validation-alert d-flex align-items-center">
        {!!externalAlertMessage ? (
          <span className="validation-alert--message mx-2">{externalAlertMessage}</span>
        ) : (
          <>
            <span className="validation-alert--message">{t(`FORM.INVALID`)}</span>{' '}
            <Button type="button" color={btnColor} className="text-nowrap" onClick={handleClick}>
              {t(`FORM.HIGHLIGHT_INVALID`)}
            </Button>
          </>
        )}
      </div>
    </Collapse>
  );
};

export default React.memo(ValidationAlert);
